export const requiredRule = (value) => !!value || `This field is required`;

export const isValidEmail = (value) => {
  if (/.+@.+\..+/.test(value)) return true;

  return `E-mail must be valid.`;
};

export const isValidUrl = (value) => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)?` + // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
      `(\\?[;&a-z\\d%_.~+=\\-\\,]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`, // fragment locator
    `i`,
  );
  return pattern.test(value) || `The URL should be valid`;
};

export const fileSizeLessThanXMb = (maxMbSize) => (files) => {
  if (!files || !files.length) {
    // File upload is not mandatory
    return true;
  }
  const MAX_SIZE = maxMbSize * 1024 * 1024;
  if (files.some((file) => file.size > MAX_SIZE)) {
    return `The file size should be up to ${maxMbSize} Mbs, please compress it with a tool like: iloveimg.com/compress-image`;
  }
  return true;
};

const mimeTypeToFileExtension = (mimeType) => mimeType.split(`/`)[1];

export const isFileTypeSupported = (supportedFileTypes) => (files) => {
  if (!files || !files.length) {
    // File upload is not mandatory
    return true;
  }
  let invalidFileType;

  for (let i = 0; i < files.length; i++) {
    const fileType = files[i].type;
    if (!supportedFileTypes.includes(fileType)) {
      invalidFileType = fileType;
      break;
    }
  }

  if (invalidFileType) {
    return `The file type ${invalidFileType} is not supported. Only the the following types are supported: 
      ${supportedFileTypes
        .map((mimeType) => mimeTypeToFileExtension(mimeType))
        .join(`, `)}
      `;
  }
  // There's no invalid file hence it's valid
  return true;
};

export const isWithinMaxFilesLimit = (maxFilesLimit) => (files) => {
  if (!files || !files.length) {
    // File upload is not mandatory
    return true;
  }
  return (
    files.length <= maxFilesLimit ||
    `You've chosen more files than the allowed maximum, you can upload up to ${maxFilesLimit} files.`
  );
};
